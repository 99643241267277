<template>
  <div class="power-set">
    <ykc-form-item :required="true" :label="'允许总功率'">
      <div class="configSetting" v-for="(item, index) in timeList" :key="index">
        <div
          class="ykc-icon-reduce"
          :class="{
            'icon-disabled': (index === 0 && timeList.length === 1) || powerDisabled,
          }"
          @click="clickTimeDel($event, index, item)"></div>
        <ykc-dropdown
          class="box-width"
          :class="{ error: item.errorStart }"
          :data="startTimeList"
          v-model="item.startValue"
          :configSet="configSet"
          :disabled="powerDisabled"
          :placeholder="'请选择开始时间'"
          @focus="selectFocusTime(1, index, item)"
          @change="selectChange($event, 1)"></ykc-dropdown>
        <div class="time-line">~</div>
        <ykc-dropdown
          class="box-width"
          :class="{ error: item.errorEnd }"
          :data="endTimeList"
          v-model="item.endValue"
          :configSet="configSet"
          :disabled="powerDisabled"
          :placeholder="'请选择结束时间'"
          @focus="selectFocusTime(2, index, item)"
          @change="selectChange($event, 2)"></ykc-dropdown>
        <ykc-input
          class="box-width"
          :class="{ error: item.errorPower }"
          v-model="item.powerValue"
          :placeholder="'请输入电站允许总功率'"
          @blur="verifyPower">
          <template #append>kw</template>
        </ykc-input>
        <div
          v-if="item.errorStart || item.errorEnd || item.errorPower"
          class="rules-error weight-syl el-form-item__error">
          {{ item.errorStart || item.errorEnd || item.errorPower }}
        </div>
      </div>

      <div v-show="isAddTime !== 2" class="configSetting">
        <div class="ykc-icon-reduce"></div>
        <ykc-input
          class="time-width"
          :placeholder="'剩余时段统一设置'"
          :disabled="true"></ykc-input>
        <ykc-input
          class="box-width"
          :class="{ error: otherError }"
          v-model="otherPower"
          @blur="verifyPower"
          :placeholder="'请输入电站允许总功率'">
          <template #append>kw</template>
        </ykc-input>
        <div
          v-if="otherError && isAddTime !== 2"
          class="rules-error weight-syl el-form-item__error">
          {{ otherError }}
        </div>
      </div>

      <div v-show="isAddTime !== 2 && !powerDisabled" class="bnt-width" @click="clickAddTime">
        <div class="ykc-icon-add"></div>
        <div class="btn-add">添加时间区间</div>
      </div>
    </ykc-form-item>
  </div>
</template>

<script>
  /* eslint-disable */
  import { mapGetters } from 'vuex';
  export default {
    name: 'setPower',
    components: {},
    props: {
      data: null, // 数据
      powerDisabled: {
        // 是否禁用，默认不禁用
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        integerNumberType: /^([1-9]\d*|[0]{1,1})$/,
        configSet: {
          label: 'label',
          value: 'value',
        },
        isAddTime: 1, // 是否可以增加时间区间 0置灰  1可点击 2隐藏
        startTimeList: [], // 开始时间集合
        endTimeList: [], // 结束时间集合
        timeList: [
          {
            startValue: '', // 开始时间值  1-25
            endValue: '', // 结束时间值
            powerValue: '', // 允许功率
            errorStart: '', // 校验提示-开始时间
            errorEnd: '', // 校验提示-结束时间
            errorPower: '', // 校验提示-功率
          },
        ],
        otherPower: '', // 其他时段功率
        otherError: '',
      };
    },
    computed: {
      ...mapGetters(['toFixed']),
    },
    mounted() {
      this.startTimeList = this.timeInit(1);
      this.endTimeList = this.timeInit(0);
      // 编辑时回填数据
      this.setData();
    },
    watch: {
      powerDisabled(val) {
        this.powerDisabled = val;
      },
      // 监控value值变化并赋值给开始/结束
      data: {
        handler(val) {
          this.setData();
        },
        // 首次绑定是否执行handle
        immediate: true,
        // 是否深度监听
        deep: true,
      },
    },
    methods: {
      init() {
        this.isAddTime = 1;
        this.timeList = [
          {
            startValue: 1, // 开始时间值
            endValue: 25, // 结束时间值
            powerValue: '', // 允许功率
            errorStart: '', // 校验提示-开始时间
            errorEnd: '', // 校验提示-结束时间
            errorPower: '', // 校验提示-功率
          },
        ];
      },
      /**
       * 回填数据
       */
      setData() {
        if (this.data) {
          this.init();
          //   this.timeList = this.data || this.init();
          console.log('this.data, this.timeList', this.data, this.timeList);
          const time = this.data || [];
          console.log('setData', this.data);
          if (this.data.length === 1) {
            // 只有一个时间区间
            this.isAddTime = 2;
            this.setTimeList(time, 0, 0);
          } else {
            this.isAddTime = 2;
            let j = 0;
            for (let i = 0; i < time.length; i++) {
              if (time[i].isDefault === 0) {
                // 非剩余时段
                if (j !== 0) {
                  this.addTimeList();
                }
                this.setTimeList(time, i, j);
                j++;
              } else {
                // 剩余时段
                this.otherPower = time[i].powerNum;
                this.isAddTime = 1;
              }
            }
          }
        }
      },
      /**
       * 给时间区间项赋值
       */
      setTimeList(item, index, key) {
        this.timeList[key].startValue = item[index].powerStartPeriod;
        this.timeList[key].endValue = item[index].powerEndPeriod;
        this.timeList[key].powerValue = item[index].powerNum;
      },
      /**
       * 增加时间区间
       */
      addTimeList() {
        const obj = {
          startValue: '', // 开始时间值
          endValue: '', // 结束时间值
          powerValue: '', // 功率
          errorStart: '', // 校验提示-开始时间
          errorEnd: '', // 校验提示-结束时间
          errorPower: '', // 校验提示-功率
        };
        this.timeList.push(obj);
      },
      /**
       * 初始化时间格式 24个时段
       */
      timeInit(type) {
        const timeBlock = [];
        for (let i = 0; i < 25; i++) {
          let a = 1;
          let b = 0;
          let label = '';
          let value = 0;
          let temp = {};
          a = Math.floor(i / 1);
          a = a > 9 ? a.toString() : `0${a}`;
          b = `00`;

          label = `${a}:${b}`;
          value = i;

          if (type === 0) {
            temp = {
              label,
              value: value + 1,
              disabled: i === 0,
            };
          } else {
            temp = {
              label,
              value: value + 1,
              disabled: i === 24,
            };
          }

          timeBlock[i.toString()] = temp;
        }
        return timeBlock;
      },
      /**
       * 开始下拉选择时间区间
       */
      selectFocusTime(type, index, item) {
        if (type === 1) {
          // 选择开始时间
          for (let i = 0; i < 24; i++) {
            this.startTimeList[i].disabled = false;
          }
          if (item.endValue) {
            // 当前区间有结束时间时，结束时间之后的开始时间选项置灰
            for (let i = item.endValue - 1; i < 24; i++) {
              this.startTimeList[i].disabled = true;
            }
          }
          for (let i = 0; i < this.timeList.length; i++) {
            const tmp = this.timeList[i];
            if (i !== index) {
              // 非当前时间区间
              if (item.endValue && item.endValue > tmp.endValue) {
                // 如有当前结束时间且当前结束时间>已选结束时间，已选结束时间之前置灰
                for (let j = 0; j < tmp.endValue - 1; j++) {
                  this.startTimeList[j].disabled = true;
                }
              } else {
                // 将已选时间区间的时间选项置灰
                for (let j = tmp.startValue - 1; j < tmp.endValue - 1; j++) {
                  this.startTimeList[j].disabled = true;
                }
              }
            }
          }
        } else {
          // 选择结束时间
          for (let i = 1; i < 25; i++) {
            this.endTimeList[i].disabled = false;
          }
          if (item.startValue) {
            // 当前区间有开始时间时，开始时间之前的开始时间选项置灰
            for (let i = 1; i < item.startValue; i++) {
              this.endTimeList[i].disabled = true;
            }
          }
          for (let i = 0; i < this.timeList.length; i++) {
            const tmp = this.timeList[i];
            if (i !== index) {
              // 非当前时间区间
              if (item.startValue && item.startValue < tmp.startValue) {
                //如果当前开始时间且当前开始时间<已选当前时间，已选当前时间之后的选项置灰
                for (let j = tmp.startValue; j < 25; j++) {
                  this.endTimeList[j].disabled = true;
                }
              } else {
                // 将已选时间区间的时间选项置灰
                for (let j = tmp.startValue; j < tmp.endValue; j++) {
                  this.endTimeList[j].disabled = true;
                }
              }
            }
          }
        }
      },
      /**
       * 选项时间区间-校验
       */
      selectChange(evt, type) {
        if (type < 4) {
          // 非剩余时段进行校验
          this.verifyTime();
        }
      },
      /**
       * 功率校验
       */
      verifyPower() {
        this.verifyTime();
        let flag = true;
        if (this.isAddTime !== 2 && !this.otherPower) {
          this.otherError = '请输入电站允许总功率';
        } else if (this.otherPower && !this.integerNumberType.test(this.otherPower)) {
          this.otherError = '请输入正确的电站允许总功率（整数）';
        } else {
          this.otherError = '';
        }

        if (this.otherError) {
          flag = false;
        }
        return flag;
      },
      /**
       * 校验时间区间
       */
      verifyTime(isMsg) {
        let flag = true;
        console.log(this.timeList);
        for (let i = 0; i < this.timeList.length; i++) {
          const item = this.timeList[i];
          if (item.startValue) {
            item.errorStart = '';
          } else if (!item.startValue) {
            flag = false;
            if (isMsg) {
              item.errorStart = '请选择开始时间';
            } else {
              break;
            }
          }
          if (item.endValue) {
            item.errorEnd = '';
          } else if (!item.endValue) {
            flag = false;
            if (isMsg) {
              item.errorEnd = '请选择结束时间';
            } else {
              break;
            }
          }
          if (item.powerValue && !this.integerNumberType.test(item.powerValue)) {
            item.errorPower = '请输入正确的电站允许总功率（整数）';
          } else if (!item.powerValue) {
            flag = false;
            if (isMsg) {
              item.errorPower = '请输入电站允许总功率';
            } else {
              break;
            }
          } else {
            item.errorPower = '';
          }
        }

        const arr = [];
        for (let i = 0; i < this.timeList.length; i++) {
          const item = this.timeList[i];
          for (let j = item.startValue; j < item.endValue; j++) {
            arr.push(j);
          }
        }
        if (arr.length === 24) {
          this.isAddTime = 2;
        } else {
          this.isAddTime = 0;
        }

        if (this.isAddTime !== 2) {
          this.isAddTime = flag ? 1 : 0;
        }
      },
      /**
       * 判断时间区间是否校验通过
       */
      isTime() {
        let flag = true;
        for (let i = this.timeList.length - 1; i >= 0; i--) {
          const item = this.timeList[i];
          if (
            !item.startValue ||
            !item.endValue ||
            !item.powerValue ||
            item.errorStart ||
            item.errorEnd ||
            item.errorPower
          ) {
            flag = false;
            break;
          }
        }

        return flag;
      },
      /**
       * 添加时间区间
       */
      clickAddTime() {
        this.verifyTime(true);
        if (this.isAddTime === 1) {
          this.addTimeList();
          this.isAddTime = 0;
        }
      },
      /**
       * 删除时间区间
       */
      clickTimeDel(evt, index, item) {
        if (this.powerDisabled) {
          // 禁用不能操作
          return;
        }
        if (index === 0 && item.startValue === 1 && item.endValue === 25) {
          return;
        }
        if (this.timeList.length > 1) {
          this.timeList.splice(index, 1);
        }
        if (this.isAddTime !== 1) {
          // 时间区间  显示增加按钮
          this.isAddTime = 1;
        }
      },
      /**
       * 保存时校验
       */
      saveVerify() {
        console.log('保存', this.timeList);
        this.verifyTime(true);
        let powerSetData = null;
        if (this.isTime() && this.verifyPower()) {
          powerSetData = [];
          for (let i = 0; i < this.timeList.length; i++) {
            const item = this.timeList[i];
            powerSetData.push({
              isDefault: 0,
              powerStartPeriod: item.startValue, // 开始时间值
              powerEndPeriod: item.endValue, // 结束时间值
              powerNum: Number(item.powerValue), // 功率
            });
          }
          if (this.isAddTime !== 2) {
            powerSetData.push({
              isDefault: 1, // 是否剩余 0非剩余  1剩余
              powerStartPeriod: 0, // 开始时间
              powerEndPeriod: 0, // 结束时间
              powerNum: Number(this.otherPower),
            });
          }
        }
        console.log(powerSetData);
        return powerSetData;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .power-set {
    .configSetting {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .ykc-icon-reduce {
        height: 16px;
        margin-top: 7px;
        margin-right: 8px;
        cursor: pointer;
        text-align: right;
        color: var(--theme-color-primary);

        &.disabled {
          color: #d7d7d7;
          cursor: not-allowed;
        }
      }
      .box-width {
        width: 30%;
        margin: 0 8px;
      }
      .time-width {
        width: 378px;
        margin: 0 8px;
      }
    }
    .bnt-width {
      width: 120px;
      display: flex;
      align-items: center;
      color: #4d8cfd;
      cursor: pointer;

      .btn-add {
        margin-left: 8px;
      }
    }
  }
</style>
