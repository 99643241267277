<template>
  <ykc-drawer
    :title="'有序控制'"
    :show="true"
    :on-close="onAddDrawerClose"
    :before-cancel="onAddDrawerCancel"
    :before-ensure="submitForm">
    <div class="drawer-main-body">
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
        <ykc-form-item label="电站名称">
          <div>{{ controlInfo.stationName || '——' }}</div>
        </ykc-form-item>
        <ykc-form-item label="电站总功率">
          <div>{{ controlInfo.totalPower || '——' }}KW</div>
        </ykc-form-item>
        <ykc-form-item label="有序开关" prop="orderlySwitch">
          <ykc-radio
            :data="[
              { id: 0, name: '关' },
              { id: 1, name: '开' },
            ]"
            v-model="ruleForm.orderlySwitch"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item
          label="有序工作模式"
          prop="powerWorkType"
          v-if="this.ruleForm.orderlySwitch === 1">
          <ykc-radio
            :data="[
              { id: 0, name: '功率均衡' },
              { id: 1, name: '先到先充' },
            ]"
            v-model="ruleForm.powerWorkType"></ykc-radio>
        </ykc-form-item>
        <PowerSet
          ref="setPowerRef"
          :data="setPowerData"
          v-if="this.ruleForm.orderlySwitch === 1"></PowerSet>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import PowerSet from './components/powerSet.vue';
  import { orderlyPage } from '@/service/apis';

  export default {
    name: 'Control',
    components: { PowerSet },
    props: {
      controlInfo: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        detail: {},
        ruleForm: {
          orderlySwitch: 0,
          powerWorkType: 0,
        },
        allowRatedPowerList: [], // 功率时段入参
        rules: {
          orderlySwitch: [{ required: true, message: '请选择有序开关', trigger: 'blur' }],
          powerWorkType: [{ required: true, message: '请选择有序工作模式', trigger: 'blur' }],
        },
        setPowerData: [], // 时段功率数据
      };
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取详情数据
       */
      getData() {
        orderlyPage
          .orderlyControlDetail({
            stationId: this.controlInfo.stationId,
          })
          .then(data => {
            if (data) {
              this.detail = data;
              this.ruleForm.orderlySwitch = data.orderlySwitch || 0;
              this.ruleForm.powerWorkType = data.powerWorkType || 0;
              this.setPowerData = data.allowRatedPowerList;
            }
          })
          .catch(() => {});
      },

      onAddDrawerClose(done) {
        done();
        this.$emit('close');
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
            this.$emit('close');
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            this.$emit('close');
          },
        });
      },
      submitForm() {
        return new Promise(resolve => {
          this.$refs.ruleForm.validate(valid => {
            const reqParams = {
              totalPower: this.controlInfo.totalPower,
              stationId: this.controlInfo.stationId,
              ...this.ruleForm,
            };
            if (this.ruleForm.orderlySwitch === 1) {
              const power = this.$refs.setPowerRef.saveVerify();
              if (power && !power.every(item => item.powerNum <= this.controlInfo.totalPower)) {
                this.$message({
                  message: '电站允许总功率必须小于电站总功率！',
                  type: 'warning',
                });
                return;
              }
              if (!power) {
                return;
              }
              this.allowRatedPowerList = power;
              reqParams.allowRatedPowerList = this.allowRatedPowerList;
            }
            if (valid) {
              orderlyPage.orderlyControlSave(reqParams).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                this.$emit('close');
                this.$emit('confirm');
                resolve();
              });
            }
          });
        });
      },
    },
  };
</script>
