<template>
  <scroll-layout class="orderly-control-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <ykc-table
      ref="YkcTable"
      operateFixedType="right"
      :data="tableData"
      :title="tableTitle"
      :columns="tableColumns"
      :operateButtons="tableOperateButtons.filter(item => item.enabled())">
      <ykc-pagination
        align="right"
        slot="pagination"
        ref="YkcPagination"
        :total="pageInfo.total"
        :page-size.sync="pageInfo.size"
        :current-page.sync="pageInfo.current"
        @size-change="handlePaginationSizeChange"
        @current-change="handlePaginationCurrentChange"></ykc-pagination>
    </ykc-table>
    <Control
      v-if="showDrawer"
      :controlInfo="controlInfo"
      @confirm="requestList"
      @close="showDrawer = false"></Control>
  </scroll-layout>
</template>

<script>
  import { code } from '@/utils';
  import { orderlyPage, barrierPage } from '@/service/apis';
  import Control from './Control.vue';

  export default {
    name: 'orderlyControlList',
    components: {
      Control,
    },
    data() {
      return {
        showDrawer: false,
        controlInfo: {},
        searchParams: {
          stationId: '',
        },
        stationIdData: [],
        tableTitle: '配置列表',
        tableOperateButtons: [], // 列表操作
        tableData: [],
        tableColumns: [
          { label: '电站名称', prop: 'stationName', minWidth: '150px' },
          { label: '电桩数量', prop: 'pileNum', minWidth: '150px' },
          {
            label: '有序开关',
            prop: 'orderlySwitch',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const switchMap = {
                  1: '开',
                  0: '关',
                };
                const switchName = switchMap[row.orderlySwitch] || '——';
                return (
                  <div>
                    <span title={switchName}>{switchName}</span>
                  </div>
                );
              },
            },
          },
          { label: '电站总功率(KW)', prop: 'totalPower', minWidth: '150px' },
          { label: '允许总功率(KW)', prop: 'allowRatedPower', minWidth: '150px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            remote: true,
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '电站名称',
            placeholder: '请输入电站名称',
            data: this.stationIdData,
          },
        ];
      },
    },
    created() {
      this.initData();
      this.requestStationData();
      this.requestList();
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('orderly:control:list:set'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.controlInfo = row;
                  this.showDrawer = true;
                }}>
                控制
              </ykc-button>
            ),
          },
          {
            text: '详情',
            enabled: () => code('orderly:control:list:detail'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.orderlySwitch !== 1}
                onClick={() => {
                  this.$router.push({
                    path: '/plantAssets/orderlyManagement/orderlyControl/Detail',
                    query: {
                      id: row.stationId,
                      stationName: row.stationName,
                      powerWorkType: row.powerWorkType,
                      totalPower: row.totalPower,
                    },
                  });
                }}>
                详情
              </ykc-button>
            ),
          },
        ];
      },
      /**
       * 获取电站数据
       */
      requestStationData() {
        barrierPage
          .findStationList({})
          .then(res => {
            console.log('获取归属电站数据+++++', res);
            this.stationIdData = res.map(item => ({
              id: item.stationId,
              name: item.stationName,
            }));
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        orderlyPage
          .orderlyControlList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);
        this.requestList();
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
    },
  };
</script>
